<template>
  <div class="Partner_tj">
    <div class="cards">
      <el-card v-for="(item, index) in shouyilist" :key="index">
        <div class="title">{{ item.name }}</div>
        <div class="content">
          <div v-show="index != 2" class="detaild">
            <div class="sy">{{ item.sy | digit }}</div>
            <div class="contrast">
              <div>
                <span>周同比</span>
                <i :class="['icons', item.ztb_status == 0 ? 'el-icon-caret-bottom' : 'el-icon-caret-top']" :style="item.ztb_status == 0 ?  'color:red' : 'color:green'"></i>
                <span>{{ item.ztb }}</span>
              </div>
              <div>
                <span>日同比</span>
                <i :class="['icons', item.rtb_status == 0 ? 'el-icon-caret-bottom' : 'el-icon-caret-top']" :style="item.rtb_status == 0 ? 'color:red' : 'color:green'"></i>
                <span>{{ item.rtb }}</span>
              </div>
            </div>
          </div>
          <div v-show="index == 2" style="font-size: 30px; line-height: 70px">{{ item.sy | digits }}</div>
        </div>
        <div class="foot">
          <el-divider></el-divider>
          <div v-show="index == 0">日收益 : {{ item.day | digit }}</div>
          <div v-show="index == 1">日提现 : {{ item.day | digit }}</div>
          <div v-show="index == 2">日新增 : {{ item.day | digits }}</div>
        </div>
      </el-card>
    </div>

    <div class="body">
      <el-card>
        <div class="title">
          <span>营收数据</span>
          <div>
            <el-button :type="active == index ? 'warning' : 'default'" size="mini" v-for="(item, index) in tabs" :key="index" @click="choose_date(index)">{{ item }}</el-button>
            <el-popover placement="bottom" width="300" v-model="visible" trigger="click">
              <el-date-picker v-model="month" size="mini" type="month" placeholder="选择月" value-format="yyyy-MM"></el-date-picker>
              <el-button type="primary" size="mini" @click="choose_date(5)">确认</el-button>
              <el-button slot="reference" size="mini" :type="active == 5 ? 'warning' : 'default'">筛选</el-button>
            </el-popover>
          </div>
        </div>
        <div class="statis-list">
          <div class="sy-from">
            <div class="item fw" @click="handleOrderType(0)">
              <div class="num">{{ sy.fw_total }}</div>
              <div class="name">服务收益</div>
            </div>
            <div class="item sc" @click="handleOrderType(1)">
              <div class="num">{{ sy.sc_total }}</div>
              <div class="name">商城收益</div>
            </div>
          </div>
<!--          <div class="tabs">-->
<!--            <div class="item" :class="{'active':queryInfo.type_id===item.type_id}" type="primary" @click="typeChange(item)" v-for="item in typeList" :key="item.type_id">{{ item.type_name }}</div>-->
<!--          </div>-->
          <ServiceTypeScroll :list="typeList" @handleServiceTypeChange="typeChange"></ServiceTypeScroll>
          <el-card>
            <el-table border :data="adminList"  style="width: 100%" :header-cell-style="{ background: '#eef1f6', color: '#606266' }">
              <el-table-column label="合伙人ID" prop="admin_id"></el-table-column>
              <el-table-column :show-overflow-tooltip="true" label="合伙人姓名" prop="admin_name"></el-table-column>
              <el-table-column :show-overflow-tooltip="true" label="服务区域">
                <template slot-scope="scope">
                  <el-tag type="danger" disable-transitions v-for="(item,index) in scope.row.city_list" :key="index" style="margin-left: 2px;">{{item}}&nbsp;&nbsp;</el-tag>
                </template>
              </el-table-column>
              <el-table-column :show-overflow-tooltip="true" label="服务类型">
                <template slot-scope="scope">
                  <el-tag type="primary" disable-transitions v-for="(item,index) in scope.row.type_list" :key="index" style="margin-left: 5px;">{{item.type_name}}&nbsp;&nbsp;</el-tag>
                </template>
              </el-table-column>
              <el-table-column :show-overflow-tooltip="true" label="服务收益" prop="sy_total"></el-table-column>
<!--              <el-table-column label="注册时间" prop="create_time" width="150"></el-table-column>-->
            </el-table>
            <Pagination :queryInfo="queryInfo" :total="total" @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange"></Pagination>
          </el-card>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
import {partnerSyTotal, syPartnerList} from "@/api/request/partner";
import Pagination from '@/components/Pagination/Pagination.vue'
import {serviceTypeAll} from "@/api/request/service";
import ServiceTypeScroll from "@/components/Page/ServiceTypeScroll.vue";
export default {
  components: {
    ServiceTypeScroll,
    Pagination
  },
  data() {
    return {
      shouyilist: [],
      tabs: ["今日", "昨日", "本周", "本月", "本年"],
      active: 0,
      month: "",
      visible: false,
      queryInfo: {
        order_type: 0,
        type_id: 0,
        pagenum: 1,
        pagesize: 10,
      },
      adminList: [],
      total: 0,
      sy: {},
      typeList: []
    };
  },
  created() {
    this.change();
  },
  mounted() {
    this.getServiceType();
  },
  methods: {
    async getServiceType() {
      const res = await serviceTypeAll({})
      if (res.code!==200) this.$message.error(res.msg);
      this.typeList = res.data;
      this.queryInfo.type_id = this.typeList.length > 0 ? this.typeList[0].type_id : 0
      this.choose_date(0);
    },
    handleOrderType(e){
      this.queryInfo.order_type = e
      this.getData();
    },
    typeChange(e) {
      console.log(e);
      this.queryInfo.type_id = e
      this.getData();
    },
    change() {
      var url = "statistic_city_partner/partnerTjData";
      let params = {};
      this.fd_post(url, params).then((res) => {
        if (res.status) {
          console.log(res.detail);
          this.shouyilist = res.detail;
        } else {
          this.$message.error(res.msg);
        }
      })
      .catch((err) => {
        this.$message.error("网络错误");
      });
    },
    choose_date(i) {
      if (i!==5) {
        this.month = ""
      }
      this.active = i;
      this.getData()
      this.getSyTotal();
    },
    async getData() {
      let params = {
        time_type: this.active + 1,
        month: this.month,
        order_type: this.queryInfo.order_type,
        type_id: this.queryInfo.type_id,
        size: this.queryInfo.pagesize,
        page: this.queryInfo.pagenum,
      };
      const res = await syPartnerList(params).catch((err) => {
        this.$message.error('网络错误');
      });
      if(res.code === 200) {
        this.total = res.total;
        this.adminList = res.rows;
      } else {
        this.$message.error(res.msg);
      }
    },
    async getSyTotal() {
      let params = {
        time_type: this.active + 1,
        month: this.month,
      };
      const res = await partnerSyTotal(params).catch((err) => {
        this.$message.error('网络错误');
      });
      if(res.code === 200) {
        this.sy = res.data;
      } else {
        this.$message.error(res.msg);
      }
    },
    //页数改变
    handleSizeChange(newSize) {
      this.queryInfo.pagenum = 1
      this.queryInfo.pagesize = newSize
      this.getData();
    },
    //页码改变
    handleCurrentChange(newPage) {
      this.queryInfo.pagenum = newPage
      this.getData();
    },
  },
};
</script>

<style lang='scss' scoped>
@import "./Citypartner_tj.scss";
</style>